import constants from '../../helpers/constants';
import { api } from '$$store/api';
import store from '../main';
import app from '../../app';
// import notifications from '../../models/userNotifications';
import _ from '../../libs/lodash';
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import {
  initBrowserConfig, initSockets, setTokenTolocalStorage,
} from './helpers';

export default {
  async loadUserSettingsDataNotification({ commit, dispatch }) {
    if (!app.config.mode.isBase) {
      return;
    }

    await api.get('/userSettings/notifications').then(async response => {
      const START_TASK = constants.NOTIFICATION_ACTIONS.START_TASK;
      const END_TASK = constants.NOTIFICATION_ACTIONS.END_TASK;
      const DEADLINE = constants.NOTIFICATION_ACTIONS.DEADLINE;
      const INVITE_TO_TEAM = constants.NOTIFICATION_ACTIONS.INVITE_TO_TEAM;
      const data = response.data;
      const setup = [];
      const typesList = [
        { type_id: 3 },
        { type_id: 4 },
      ];

      data.actions.forEach(action => {
        const obj = {};

        obj.locale = action.locale;
        obj.id = action.id;
        typesList.forEach(config => {
          const type_id = config.type_id;
          const filteredType = data.types.filter(type => type.id === type_id);

          const filteredConfig = data.configs.filter(conf => conf.type_id === type_id).filter(conf => conf.action_id === action.id);

          if (filteredConfig.length) {
            obj[filteredType[0].code] = !!filteredConfig[0].active;
          }
        });
        if (obj.id === INVITE_TO_TEAM) {
          return;
        }
        if (obj.id === START_TASK || obj.id === END_TASK || obj.id === DEADLINE) {
          setup.unshift(obj);
        } else {
          setup.push(obj);
        }
      });
      const settings = {
        setup,
        notification_sound: data.notification_sound,
        notification_first_setup: data.notification_first_setup,
        notification_welcome: data.notification_welcome,
        task_start_notify_delay: data.task_start_notify_delay,
        task_end_notify_delay: data.task_end_notify_delay,
        deadline_notify_delay: data.deadline_notify_delay,
      };

      // notifications.baseData = data;
      // notifications.settings = settings;

      commit('setUserSettingsDataNotification', { settings });
      commit('setBaseData', data);

      // ???? need check
      if (data.configs && data.configs.length < 12) {
        await api.get('/userSettings/notifications/default')
          .then(() => dispatch('loadUserSettingsDataNotification'));
      }
    });

    // init all sockets for user notification
    await initSockets(store, app);

    // init support for browser + sound notify
    await initBrowserConfig(store, app);
  },

  async updateUserSettingsDebounced() {
    await _.debounce(this.updateUserSettings, 10000);
  },

  async loadData({ commit, getters }, page) {
    const notificationStore = getters.getNotificationStore;

    return api.get(`/notification/team/${notificationStore.limitPerPage}/${page}`).then(async response => {
      const data = response.data;

      if (data.length) {
        data.forEach(item => {
          item.action_date = new Date(item.action_date);
        });

        data.sort((a, b) => b.action_date - a.action_date);
      }

      await commit('loadNotifications', { data });

      if (data.length < notificationStore.limitPerPage) {
        app.trigger('userNotifications:removeShowMore');
      }
    });
  },

  async updateSetupToDefault({ dispatch }, payload) {
    return api.get('/userSettings/notifications/default')
      .then(() => dispatch('loadUserSettingsDataNotification'))
      .catch(err => {
        console.error(err);
      });
  },

  async readAll({ commit }) {
    // const collection = notifications.serialize();

    await commit('readAll');
    await commit('setNotificationCount', { count: 0 });
    // collection.forEach(note => {
    //   note.read = true;
    //   notifications.updateItem(note.id, note);
    // });
    // app.trigger('userNotifications:updateReadCount', 0);
    app.socket.emit('readAllUserNotifications', user.id);
  },

  async subscribeToPushNotifications({ commit }) {
    let firebaseApp = initializeApp({
      messagingSenderId: '1004998631825', // Firebase senderId,
      projectId: 'ganttpro-d0a9b',
      apiKey: 'AIzaSyD5chP9IjTSQqpamP7flzY5ihZ6018_dgQ',
      appId: '1:1004998631825:web:b5417c317e0f70c4fa7a30'
    });

    const messaging = getMessaging(firebaseApp);

    // vapidKey from https://console.firebase.google.com/u/1/project/ganttpro-d0a9b/settings/cloudmessaging 
    getToken(messaging, { vapidKey: 'BLNcLwjgyFjuUrVL12f2_wMGx_K7OmcOHFuwRqc-9q-hngJ8comnd012Lf5j6At0tntJuBPTEKv86qHyF01p3Ac' }).then((currentToken) => {
      if (currentToken) {
        api.post('/userSettings/desktopToken', {
          token: currentToken,
        }).then(() => {
          if (window.localStorage.getItem('sentFirebaseMessagingToken') !== currentToken) {
            setTokenTolocalStorage(currentToken);
          }
        });
      } else {
        setTokenTolocalStorage(false);
      }
    }).catch((err) => {
      console.warn(err);
    });

    // let firebaseMessaging;
    // const isIE = /* @cc_on!@ */false || !!document.documentMode;

    // if (isIE) return;

    // firebaseMessaging = firebase.messaging();

    // firebaseMessaging.requestPermission()
    //   .then(() => firebaseMessaging.getToken())
    //   .then(currentToken => {
    //     if (currentToken) {
    //       api.post('/userSettings/desktopToken', {
    //         token: currentToken,
    //       }).then(() => {
    //         if (window.localStorage.getItem('sentFirebaseMessagingToken') !== currentToken) {
    //           setTokenTolocalStorage(currentToken);
    //         }
    //       });
    //     } else {
    //       setTokenTolocalStorage(false);
    //     }
    //   })
    //   .catch(err => {
    //     console.warn(err);
    //   });
  },

  async updateSetup({ commit }, payload) {
    await api.post('/userSettings/notifications', payload);
  },

  async setSoundConfig({ commit }, payload) {
    commit('setSoundConfig', payload);
  },

  async updateUserSettings({ commit }, payload) {
    await api.post('/userSettings', payload.data)
      .then(() => {
        commit('updateUserSettings', payload.data);
        if (payload.showPermissionPopup) {
          app.trigger('showPushPermissionPopup');
        }
      });
  },

  async switchDesktopNotifications({ dispatch, commit, getters }, payload) {
    const userNotificationSetting = getters.getNotificationsSettings;

    if (!payload.trigger && _.every(userNotificationSetting.setup, ['desktop', false])) return;

    return api.put('/userSettings/notifications/desktopSwitch', { trigger: payload.trigger })
      .then(data => dispatch('loadUserSettingsDataNotification'))
      .then(() => Promise.resolve());
  },

  async setCount({ commit }, payload) {
    commit('setNotificationCount', payload);
  },

  async isLoaded({ commit }) {
    commit('setLoaded');
  },

  async incrementCount({ commit, getters }) {
    const getCount = getters.getNotificationsCount;

    commit('setNotificationCount', { count: getCount + 1 });
  },

  async addNewNotification({ commit }, payload) {
    commit('addNewNotification', payload);
  },

};
