import app from '../../../app';
import customDayTemplateModel from '../../../models/customDayTemplates';
import dateHelper from '../../../helpers/dateFormats';
import constants from '../../../helpers/constants';

import zoomObject from '../../settings/include/settingsZoom';
import projects from '../../../models/projects';
import globalStore from '../../../store/main';

const __ = window.__;

var innerObject = {
  init: {
    run() {
      innerObject.init.workTime();
      innerObject.init.initDurationData(gantt.config.durationData);
    },
    reinit(durationData) {
      innerObject.init.workTime();
      innerObject.init.initDurationData(durationData);
    },
    workTime(workTimeState) {
      gantt.config.correct_work_time = false;
      gantt.config.work_time = true;
    },
    initDurationData(durationData) {
      const setConfigByZoomStrategy = {
        hour: innerObject.init.helpers.setGanttZoomByHour,
        day: innerObject.init.helpers.setGanttZoomByDay,
        week: innerObject.init.helpers.setGanttZoomByWeek,
        month: innerObject.init.helpers.setGanttZoomByMonth,
        quarter: innerObject.init.helpers.setGanttZoomByQuarter,
        year: innerObject.init.helpers.setGanttZoomByYear,
      };

      setConfigByZoomStrategy[durationData.mode](durationData);
      innerObject.init.initWorkTime(durationData);
      zoomObject.handlers.setZoomSliderValue(constants.ZOOM.modeToZoomValue[durationData.mode]);
    },
    initWorkTime(originalDurationData, ganttId) {
      const durationData = _.cloneDeep(originalDurationData);
      let work_days = [];
      let arrHours = []; // durationData.startTime < durationData.endTime ? [durationData.startTime, durationData.endTime] : [durationData.startTime];
      const allDays = [0, 1, 2, 3, 4, 5, 6];

      gantt.clearCash('isWorkDays');

      const setWorkTime = function (durationData) {
        let hoursWork;

        if (durationData.showTime?.length) {
          hoursWork = durationData.showTime;
        } else if (durationData.startTime !== undefined && durationData.endTime) {
          hoursWork = [durationData.startTime, durationData.endTime];
        } else {
          hoursWork = [0, 24];
        }

        return hoursWork;
      };

      arrHours = setWorkTime(durationData);

      if (durationData.showDay && durationData.showDay.length) {
        work_days = durationData.showDay;
      } else {
        work_days = [1, 2, 3, 4, 5, 6, 7];
      }

      work_days.map((day, index) => {
        if (day == 7) {
          work_days[index] = 0;
        }
      });

      // fix from owner gantt
      gantt.config.worktimes = {
        global: gantt.getCalendar('global').getConfig(),
      };

      // gantt.config.worktimes.global.dates = {};

      gantt.config.durationData = durationData;

      allDays.map(dayOfWeekNumber => {
        if (
          work_days.indexOf(dayOfWeekNumber) !== -1
          && dayOfWeekNumber < 7
        ) {
          gantt.setWorkTime({ day: dayOfWeekNumber, hours: arrHours });
        } else {
          gantt.setWorkTime({ day: dayOfWeekNumber, hours: false });
        }
      });

      // clear custom days
      const oldDates = gantt.getCalendar('global').getConfig().dates;

      Object.keys(oldDates).forEach(day => {
        if (!allDays.includes(+day)) {
          delete gantt.getCalendar('global').getConfig().dates[day];
        }
      });

      innerObject.init.initWorkTimeCustomDays(arrHours, ganttId);
    },
    initWorkTimeCustomDays(defaultWorkHours, ganttId) {
      const customDays = customDayTemplateModel.setAndGetCustomDaysForProject(ganttId || gantt.config.gantt_id);
      let stepDay = {};
      let tempDate = {};
      let tempDateTo = {};
      const datesPeriod = globalStore.getters['tasksModel/getMaxAndMinDateForProject'](ganttId || gantt.config.gantt_id, 1, true);
      let tempEndDate = {};

      // set repeat days
      customDays.repeat.forEach(repeatDayData => {
        // TODO check field repeat_from - repeat-to
        if (!repeatDayData.hours.length) {
          // if weekend
          repeatDayData.hours = false;
        }

        switch (repeatDayData.repeat) {
        case 'week':
          stepDay = dateHelper.createDayStartedAt00(repeatDayData.from);

          tempEndDate = repeatDayData.repeat_to ? dateHelper.createDayStartedAt00(repeatDayData.repeat_to) : datesPeriod.maxDate;
          tempDateTo = dateHelper.createDayStartedAt00(repeatDayData.to);

          while (stepDay.valueOf() <= tempEndDate.valueOf()) {
            innerObject.init.helpers.setWorkTimeByDate(
              new Date(stepDay),
              repeatDayData.to ? tempDateTo : null,
              repeatDayData.hours,
            );

            stepDay.setDate(stepDay.getDate() + 7);
          }

          break;
        case 'month':
          tempDate = dateHelper.createDayStartedAt00(repeatDayData.from).getDate();

          tempDateTo = dateHelper.createDayStartedAt00(repeatDayData.to).getDate();
          stepDay = dateHelper.createDayStartedAt00(repeatDayData.from);
          tempEndDate = repeatDayData.repeat_to ? dateHelper.createDayStartedAt00(repeatDayData.repeat_to) : datesPeriod.maxDate;

          while (stepDay.valueOf() <= tempEndDate.valueOf()) {
            innerObject.init.helpers.setWorkTimeByDate(
              new Date(stepDay.getFullYear(), stepDay.getMonth(), tempDate),
              repeatDayData.to ? new Date(stepDay.getFullYear(), stepDay.getMonth(), tempDate) : null,
              repeatDayData.hours,
            );

            stepDay.setMonth(stepDay.getMonth() + 1);
          }
          break;
        case 'year':
          tempDate = dateHelper.createDayStartedAt00(repeatDayData.from);
          tempDateTo = dateHelper.createDayStartedAt00(repeatDayData.to);
          stepDay = dateHelper.createDayStartedAt00(repeatDayData.from);

          tempEndDate = repeatDayData.repeat_to
            ? dateHelper.createDayStartedAt00(repeatDayData.repeat_to)
            : datesPeriod.maxDate;

          while (stepDay.valueOf() <= tempEndDate.valueOf()) {
            innerObject.init.helpers.setWorkTimeByDate(
              new Date(stepDay),
              repeatDayData.to
                ? dateHelper.createDayStartedAt00(repeatDayData.to)
                : null,
              repeatDayData.hours,
            );

            stepDay.setFullYear(stepDay.getFullYear() + 1);
          }
          break;
        }
      });

      customDays.exception.forEach(customDayData => {
        if (!customDayData.from && !customDayData.to) {
          return false;
        }

        if (!customDayData.hours.length) {
          // if weekend
          customDayData.hours = false;
        }

        innerObject.init.helpers.setWorkTimeByDate(
          dateHelper.createDayStartedAt00(customDayData.from),
          customDayData.to
            ? dateHelper.createDayStartedAt00(customDayData.to)
            : null,
          customDayData.hours,
        );
      });
    },
    helpers: {
      dropDeprecatedScaleValues() {
        gantt.config.subscales = undefined;
        gantt.config.step = undefined;
        gantt.config.scale_unit = undefined;
        gantt.config.date_scale = undefined;
      },
      getWeekScale() {
        return {
          unit: 'week',
          step: 1,
          index: 1,
          template(ndate) {
            const nday = ndate.getDay();
            const first_thursday = new Date(ndate.valueOf());

            first_thursday.setDate(ndate.getDate() + (4 - nday));

            const year_number = first_thursday.getFullYear(); // year of the first Thursday
            const ordinal_date = Math.round((first_thursday.getTime() - new Date(year_number, 0, 1).getTime()) / 86400000); // ordinal date of the first Thursday - 1 (so not really ordinal date)
            const week_number = 1 + Math.floor(ordinal_date / 7);

            const startDateDay = ndate.getDate();
            const endDate = ndate;

            endDate.setDate(endDate.getDate() + 6);
            const endDateDay = endDate.getDate();

            return `<span title="${__('days_week_range')}">${startDateDay}-${endDateDay}</span>&nbsp;<span title="${__('week_number')}">(${week_number}${__('week_in_time_string')})</span>`;
            // return week_number + " " + __('gantt_week');
          },
        };
      },
      setGanttZoomByHour() {
        innerObject.init.helpers.dropDeprecatedScaleValues();
        gantt.config.scale_height = 38;
        let hourFormat = '%H';

        gantt.config.drag_date = dateHelper.getDateFormatForGanttGrid();
        gantt.config.drag_label_width = 150;
        gantt.config.min_column_width = 20;

        if (!dateHelper.getUserTimeFormat()) {
          hourFormat = '%h';
        }

        gantt.config.min_duration = 30;
        gantt.config.skip_off_time = true;

        gantt.config.scales = [
          { unit: 'day', step: 1, format: '%d %M' },
          { unit: 'hour', step: 1, format: hourFormat },
        ];

        gantt.templates.scale_cell_class = function (date) {
          if (date.getDay() == 0 || date.getDay() == 6) {
            return '';
          }
        };

        gantt.config.timeline_highlight = false;
        gantt.templates.timeline_column_class = function (item, date) {
          return '';
        };

        gantt.templates.timeline_cell_class = function (task, date) {
          const zoomInDays = gantt.config.durationData.mode === 'day';

          if (zoomInDays && !gantt.isWorkTime({ date, task, unit: 'day' })) {
            return 'weekend';
          }

          const zoomInHours = gantt.config.durationData.mode === 'hour';

          if (zoomInHours && !gantt.isWorkTime({ date, task, unit: gantt.config.duration_unit })) {
            return 'weekend';
          }

          return '';
        };
      },
      setGanttZoomByDay(durationData) {
        innerObject.init.helpers.dropDeprecatedScaleValues();
        gantt.config.scale_height = 38;
        gantt.config.drag_date = dateHelper.getDateFormatForGanttGrid();
        gantt.config.drag_label_width = 150;
        gantt.config.min_column_width = 26;
        gantt.ignore_time = false;

        gantt.config.scales = [
          { unit: 'month', step: 1, date: '%F %Y' },
          {
            unit: 'day',
            step: 1,
            date: '%d',
            css(date) {
              if (!gantt.isWorkDay(date)) {
                return ' weekend';
              }

              return '';
            },
          },
        ];

        gantt.config.timeline_highlight = true;

        gantt.templates.timeline_column_class = function (date) {
          if (gantt.config.worktimes && !gantt.isWorkDay(date)) {
            return 'weekend';
          }

          return '';
        };

        gantt.templates.timeline_cell_class = function (task, date) {
          const zoomInDays = gantt.config.durationData.mode === 'day';

          // if (zoomInDays && !gantt.isWorkTime({ date, task, unit: 'day' })) {
          if (zoomInDays && !gantt.isWorkDay(date, gantt.getCalendar(task.gantt_id))) {
            if (task.type === 'button' || !task.gantt_id) {
              return '';
            }

            return 'weekend';
          }

          const zoomInHours = gantt.config.durationData.mode === 'hour';

          if (zoomInHours && !gantt.isWorkTime({ date, task, unit: gantt.config.duration_unit })) {
            return 'weekend';
          }

          return '';
        };

        gantt.templates.scale_cell_class = false;
      },
      setGanttZoomByWeek() {
        innerObject.init.helpers.dropDeprecatedScaleValues();
        gantt.config.scale_height = 38;
        gantt.config.min_column_width = 70;
        gantt.config.min_duration = 30;
        gantt.ignore_time = false;
        gantt.config.scales = [
          { unit: 'month', step: 1, date: '%F %Y' },
          innerObject.init.helpers.getWeekScale(),
        ];
        gantt.templates.scale_cell_class = false;
        gantt.config.timeline_highlight = false;
        gantt.templates.timeline_column_class = function (item, date) {
          return '';
        };
      },
      setGanttZoomByMonth() {
        innerObject.init.helpers.dropDeprecatedScaleValues();
        gantt.config.scale_height = 38;
        gantt.config.step = 1;
        gantt.config.min_column_width = 65;

        gantt.config.scales = [
          { unit: 'year', step: 1, format: '%Y' },
          { unit: 'month', step: 1, format: '%M' },
        ];

        gantt.templates.scale_cell_class = false;
        gantt.config.timeline_highlight = false;
        gantt.templates.timeline_column_class = function (item, date) {
          return '';
        };
      },

      setGanttZoomByQuarter() {
        innerObject.init.helpers.dropDeprecatedScaleValues();
        gantt.config.scale_height = 38;
        gantt.config.min_column_width = 45;
        gantt.templates.scale_cell_class = false;
        gantt.config.scales = [
          { unit: 'quarter', step: 1, format: date => `${__('settings_quarter')} ${Math.ceil((date.getMonth() + 1) / 3)}, ${date.getFullYear()}` },
          { unit: 'month', step: 1, format: '%M' },
        ];
        gantt.config.timeline_highlight = false;
        gantt.templates.timeline_column_class = function (item, date) {
          return '';
        };
      },
      setGanttZoomByYear() {
        innerObject.init.helpers.dropDeprecatedScaleValues();
        gantt.config.scale_height = 38;
        gantt.config.min_column_width = 45;
        gantt.config.scales = [
          { unit: 'year', step: 1, format: '%Y' },
        ];
        gantt.templates.scale_cell_class = false;
        gantt.config.timeline_highlight = false;
        gantt.templates.timeline_column_class = function (item, date) {
          return '';
        };
      },
      setWorkTimeByDate(dayFrom, dayTo, dayHours) {
        if (!dayTo) {
          // if single date
          gantt.setWorkTime({ date: dayFrom, hours: dayHours });
        } else {
          // if repeatable
          while (dayFrom.valueOf() <= dayTo.valueOf()) {
            gantt.setWorkTime({ date: dayFrom, hours: dayHours });
            dayFrom.setDate(dayFrom.getDate() + 1);
          }
        }
      },
    },
  },
  handlers: {
    changeWorkTime(durationData) {
      const projectData = projects.getActiveProjectData();

      if (projectData && !durationData) {
        durationData = projectData.config.durationData;
      } else {
        durationData = durationData || gantt.config.durationData;
      }

      gantt.config.durationData = gantt.copy(durationData);

      innerObject.init.initDurationData(durationData);
    },
  },
};

const outputObject = {
  init: {
    run: innerObject.init.run,
    beforeInit: innerObject.init.beforeInit,
    afterInit: innerObject.init.afterInit,
    reinit: innerObject.init.reinit,
    initWorkTime: innerObject.init.initWorkTime,
  },
};

app.on('changeUserDateTimeFormat', () => {
  if (gantt.config.durationData.mode === 'hour') {
    gantt.config.drag_date = dateHelper.getDateFormatForGanttGrid();
    innerObject.handlers.changeWorkTime();
  } else {
    gantt.config.drag_date = dateHelper.getDateFormat();
  }

  gantt.templates.drag_date = gantt.date.date_to_str(gantt.config.drag_date);
});

app.on('change:work:time', (ignoreReinit, ganttId = gantt.config.gantt_id) => {
  if (!app.config.mode.isBase) {
    return;
  }

  const oldCalendar = gantt.getCalendar(ganttId);

  if (!oldCalendar) {
    return;
  }

  const oldDates = _.cloneDeep(oldCalendar.getConfig().dates);
  const oldHours = _.cloneDeep(oldCalendar.getConfig().hours);
  const project = projects.getProjectDataById(ganttId);

  projects.initProjectCalendar(project);

  innerObject.handlers.changeWorkTime(ignoreReinit ? gantt.config.durationData : null);
  !ignoreReinit && app.trigger('gantt:reinitConfig');

  const newCalendar = gantt.getCalendar(ganttId);
  const changeWorkingDays = !_.isEqual(oldDates, newCalendar.getConfig().dates);
  const changeWorkingHours = !_.isEqual(oldHours, newCalendar.getConfig().hours);

  if (changeWorkingDays || changeWorkingHours) {
    gantt.clearCash('isWorkDays');
    globalStore.dispatch('tasksModel/recalculateStartDatesForTasksByGanttId', { ganttId, isChangedCalendar: true });
    app.trigger('gantt:reinit');
    gantt.is_workload_mode && app.trigger('project:settings:workload:recalculate');
  }
});

export default outputObject;
