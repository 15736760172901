import '../../libs/howler';

const isIE = /* @cc_on!@ */false || !!document.documentMode;
const isFirefox = typeof InstallTrigger !== 'undefined';
const isChrome = !!window.chrome;
let firebaseMessaging;

export const initSockets = (store, app) => {
  app.socket.on('updateReadCount', count => {
    store.dispatch('userNotification/setCount', { count });
  });

  app.socket.on('newUserNotification', data => {
    const userNotificationStore = store.getters['userNotification/getNotificationStore'];
    const newNotification = JSON.parse(data);

    newNotification.action_date = new Date(newNotification.action_date);
    store.dispatch('userNotification/incrementCount');
    store.dispatch('userNotification/addNewNotification', newNotification);

    app.trigger('userNotifications:new');

    if (userNotificationStore?.settings.notification_sound) {
      // Play sound if it's available
      userNotificationStore.sound && userNotificationStore.sound.play();
    }
  });
};

export const setTokenTolocalStorage = currentToken => {
  window.localStorage.setItem(
    'sentFirebaseMessagingToken',
    currentToken || '',
  );
};

export const getLocalVariables = () => ({
  isIE,
  isFirefox,
  isChrome,
  firebaseMessaging,
});

export const initBrowserConfig = (store, app) => {
  if (isIE) {
    window.Notification = {};
  }

  const notifications = store.getters['userNotification/getNotificationStore'];

  if (!app.debug) {
    GT.models.notifications = notifications;
  }

  if (notifications) {
    store.commit('userNotification/updateUserSettings', {
      browserSupport: isFirefox || isChrome,
      isLoaded: true,
    });

    window.addEventListener('click', function initSound() {
      // this is done to remove warning message from console about audio context
      // because it is available only after interaction with page due to chrome security reasons
      const notifications = store.getters['userNotification/getNotificationStore'];

      if (notifications && notifications.sound) return;

      // Initialize sound only after user interaction (click event)
      store.dispatch('userNotification/setSoundConfig', {
        sound: new Howl({
          src: [
            'https://cdn.ganttpro.com/statics/media/audio/bell.mp3',
            'https://cdn.ganttpro.com/statics/media/audio/bell.wav',
          ],
          buffer: true,
          html5: true,
          onplayerror(id, err) {
            store.commit('userNotification/updateUserSettings', {
              audioBlocked: true,
            });
            store.dispatch('userNotification/updateUserSettings', { data: { notification_sound: 0 } });
          },
        }),
      });

      // Remove the event listener after initializing sound
      window.removeEventListener('click', initSound);
    }, false);

    app.trigger('userNotifications:addReadCount', notifications.settings.notification_welcome);

    if (window.Notification && !isIE) {
      const permission = window.Notification.permission;

      if (permission === 'granted') {
        store.dispatch('userNotification/subscribeToPushNotifications');
      }
      if (permission === 'default' || permission === 'denied') {
        // notifications.switchDesktopNotifications(0);
      }
    }
  }
};