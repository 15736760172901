import app from '../../../app';

import actionButtonsModule from './gridActionButtons';

import _ from '../../../libs/lodash';
import massChangeModel from './massChange';

import routerHelper from '../../../helpers/router';
import constants from '../../../helpers/constants';
import colorHelper from '../../../helpers/color';

import multiViewsProjects from '../../../models/multiViewsProjects';
import ganttViewModel from '../../../models/ganttViewModel';
import projectsModel from '../../../models/projects';
import { innerHandlers as taskSettingsHandlers } from '../../taskSettings/main';

import globalStore from '../../../store/main';
import massChangeModule from './massChange';
import Store from '../../../store/main';
import rights from '../../../components/rights/index';
import { checkPricingAccess } from '$$helpers/pricingHelper';

const __ = window.__;

const innerHandlers = {
  addEventListeners() {
    gantt.attachEvent('onContextMenu', (taskId, linkId, e) => {
      const src = innerHelpers.getClickElement(e);
      const ganttTask = gantt.getTask(taskId);
      const childs = gantt.getChildren(taskId);

      if (ganttTask.type === 'project' && ganttTask.parent === 0 && childs.length <= 1) {
        return;
      }

      const canShowContextMenu = [
        !massChangeModel.isEnabled(),
        !routerHelper.isTaskSettingsRoute(),
        !innerHelpers.isTaskRow(src),
        taskId,
        !innerHelpers.isButtonRow(taskId),
        !(innerHelpers.isTotalEstimate(taskId) && (gantt.config.multiview || globalStore.getters['headerView/isActiveFilter'] || gantt.isJira)),
        !gantt.config.readonly,
      ];

      if (_.every(canShowContextMenu)) {
        const config = {
          node: innerHelpers.getClickElement(e),
          position: innerHelpers.getClickPosition(e),
        };

        innerHandlers.showContextMenu(config, taskId);
      } else {
        innerHandlers.hideContextMenuPopup();
      }

      actionButtonsModule.handlers.hideColorboard();
    });

    gantt.attachEvent('onGanttScroll', _.debounce((x, y) => {
      const $$contextMenuPopup = $$(innerViews.main.id);

      if ($$contextMenuPopup.isVisible()) {
        if (y > 0) {
          innerHandlers.hideContextMenuPopup();
          gantt.saveTaskEditors(true);

          return true;
        }

        if (x > 0) {
          innerHandlers.hideContextMenuPopup();

          return true;
        }
      }
    }, 50));

    gantt.showContextMenu = function (element) {
      const config = {
        node: element,
      };

      const taskId = element.dataset.taskId;

      if (taskId) {
        innerHandlers.showContextMenu(config, taskId);
      }
    };

    app.on('popup:show', () => {
      innerHandlers.hideContextMenuPopup(true);
    });
    app.on('taskSettings:save', innerHandlers.hideContextMenuPopup);
    app.on('taskSettings:init', innerHandlers.hideContextMenuPopup);
  },
  getWidthForMenu: () => {
    innerSettings.contextMenuWidth = 236;

    if (user.locale === 'de') {
      innerSettings.contextMenuWidth = 344;
    }

    if (user.locale === 'pt' || user.locale === 'es') {
      innerSettings.contextMenuWidth = 266;
    }
  },
  beforeInit() {
    webix.ui({
      id: innerViews.main.id,
      view: 'popupWithoutPoint',
      css: 'context_menu_popup'
        + '',
      hidden: true,
      borderless: true,
      autoheight: true,
      width: innerSettings.contextMenuWidth,
      body: {
        rows: [
          innerViews.contextMenuList,
        ],
      },
      on: {
        onHide() {
          gantt.setBlockingCollaborationByPopup(innerViews.contextMenuList.id, false);
          app.trigger('gantt:contextMenu:hidden');
          const nodes = document.querySelectorAll('.column-options-cog');

          _.each(nodes, node => {
            node && node.classList.remove('active');
          });
        },
      },
    });
  },
  beforeRun: () => {
    innerHandlers.getWidthForMenu();
    innerHandlers.beforeInit();
  },
  run() {
    const $$contextMenuList = $$(innerViews.contextMenuList.id);

    $$contextMenuList.clearAll();
    $$contextMenuList.parse(innerHelpers.getMenuItems(contextMenuItemsConfig));

    innerHandlers.addEventListeners();
  },
  changeMenuIconForTaskColor($$contextMenuList, taskId) {
    const task = gantt.getTask(taskId);
    const item = task.$level === 1 && gantt.config.multiview ? 'choose_project_color' : 'choose_task_color';
    let colorValue;

    if (task.color) {
      colorValue = parseInt(task.color, 10) ? colorHelper.getColorStr(parseInt(task.color, 10)) : task.color;
      $$contextMenuList.updateItem(item, {
        icon: `<div class='context_menu_list_icon_color' style='background-color: ${colorValue}'></div>`,
      });
    }
  },
  showAllMenuItems(view, itemsConfig) {
    _.each(itemsConfig, item => {
      view.showItem(item.id);
    });
  },
  filterMenuItems($$contextMenuList, taskId) {
    innerHandlers.showAllMenuItems($$contextMenuList, contextMenuItemsConfig);
    const task = gantt.getTask(taskId);

    actionButtonsModule.handlers.filterMenuItems($$contextMenuList, task);
    innerHandlers.filterMenuItemsByRights($$contextMenuList, task);
  },
  filterMenuItemsByRights(menuList, task) {
    const blockItems = [];

    // mass change condition
    if (!rights.project.hasRightSomeOne(task.gantt_id, [
      'static_fields',
      'delete_task',
      'static_fields_4_only',
      'custom_field_edit',
    ]) || !checkPricingAccess('bulk_change') || gantt.config.multiview) {
      blockItems.push('select_task_for_masschange');
    }

    // check jira project
    const project = projectsModel.getProjectDataById(task.gantt_id);

    if (project.is_jira) {
      blockItems.push('select_task_for_masschange');
    }

    if (!rights.project.hasRight(task.gantt_id, 'create_task')) {
      blockItems.push(...[
        'add_subtask',
        'add_sibling_task',
        'add_sibling_milestone',
        'add_child_milestone',
        'move_indent',
        'move_outdent',
        'convert_in_milestone',
        'convert_in_task',
        'separatorBefore',
      ]);
    }

    if (!rights.project.hasRight(task.gantt_id, 'delete_task')) {
      blockItems.push(...[
        'delete_task',
      ]);
    }

    if (!rights.project.hasRight(task.gantt_id, 'copypast')) {
      blockItems.push(...[
        'copy_task',
        'paste_task',
        'paste_task_disabled',
      ]);
    }

    if (!rights.project.hasRight(task.gantt_id, 'static_fields')) {
      blockItems.push(...[
        'choose_task_color',
        'choose_project_color',
      ]);
    }

    if (blockItems.length) {
      const menuItems = menuList.serialize();
      let visibleCount = 0;

      menuItems.forEach(item => {
        if (blockItems.includes(item.id)) {
          menuList.hideItem(item.id);
        } else {
          visibleCount++;
        }
      });

      if (visibleCount === 2) {
        menuList.hideItem('separatorAfter');
      }
    }
  },
  enablePasteMenuItem(webixUI) {
    webixUI.hideItem('paste_task_disabled');
    webixUI.showItem('paste_task');
  },
  showContextMenuPopup(config, taskId) {
    const $$contextMenuPopup = $$(innerViews.main.id);
    const node = document.querySelector(`.column-options-cog[data-task-id="${taskId}"]`);

    const nodes = document.querySelectorAll('.column-options-cog');

    _.each(nodes, node => {
      node && node.classList.remove('active');
    });

    if (config.position) {
      $$contextMenuPopup.show(config.position);
      node && node.classList.add('active');
      userExtAnalytics.log('gantt_context_menu_show_open', { from: 'right click' });
    } else if (config.node) {
      const coords = node.getBoundingClientRect();

      node && node.classList.add('active');
      const nodePopup = document.querySelectorAll('.context_menu_popup');
      const docHeight = document.body.clientHeight;
      const docWidth = document.body.clientWidth;
      const popupHeight = parseInt(nodePopup[2].style.height);
      const popupWidth = parseInt(nodePopup[2].style.width);

      if ((coords.y + popupHeight + 24) > docHeight) {
        // coords.y += 2;
        coords.x += 30;
      } else {
        coords.y -= 26;
        coords.x += 30;
      }
      if ((coords.x + popupWidth + 24) > docWidth) {
        coords.x -= popupWidth + 50;
      }
      $$contextMenuPopup.show(coords);
      userExtAnalytics.log('gantt_context_menu_show_open', { from: 'button' });
    }
  },
  showContextMenu(config, taskId) {
    const $$contextMenuList = $$(innerViews.contextMenuList.id);

    $$contextMenuList.config.taskId = taskId;
    innerHandlers.filterMenuItems($$contextMenuList, taskId);

    const menuItems = $$contextMenuList.serialize();

    if (menuItems.length) {
      innerHandlers.changeMenuIconForTaskColor($$contextMenuList, taskId);

      innerHandlers.showContextMenuPopup(config, taskId);
      app.trigger('gantt:contextMenu:showed', +taskId);
    }
  },
  hideContextMenuPopup(needDelay) {
    const $$contextMenuPopup = $$(innerViews.main.id);

    if ($$contextMenuPopup.isVisible()) {
      $$contextMenuPopup.config.taskId = null;

      if (needDelay) {
        webix.delay(() => {
          $$contextMenuPopup.hide();
        });
      } else {
        $$contextMenuPopup.hide();
      }
    }
  },
  editTaskRoute(taskId) {
    const taskViewPath = `${routerHelper.getCurrentRoute().path}/task/${taskId}`;
    const taskSettingsRoute = routerHelper.isTaskSettingsRoute();

    if (taskSettingsRoute) {
      const item = gantt.getTask(taskId);

      if (item.type === gantt.config.types.button || !item.parent || item.parent === 1) {
        return;
      }

      routerHelper.pushRoute({
        name: taskSettingsRoute,
        params: {
          taskId: String(taskId),
        },
      });
    } else {
      routerHelper.changeRoute(taskViewPath);
    }
  },
  openEditTaskPopup(webixUI, id, node) {
    const taskId = webixUI.config.taskId;

    innerHandlers.hideContextMenuPopup();

    innerHandlers.editTaskRoute(taskId);

    userExtAnalytics.log('context_menu_click', { line: 'edit task' });
    userExtAnalytics.log('gantt_task_settings_open', {
      from: routerHelper.analyticsFromRoute(),
      route: `${routerHelper.analyticsRoute()}/ContextMenu`,
    });
  },
  addTaskCommonHandler(webixUI, id, node) {
    actionButtonsModule.handlers.addTaskClick(id, webixUI.config.taskId);
    innerHandlers.hideContextMenuPopup();

    userExtAnalytics.log('context_menu_click', { line: id.replace(/_/g, ' ') });

    userExtAnalytics.log('gantt_add_task_grid_done', {
      type: id.includes('task') ? 'task' : 'milestone',
      way: 'button',
    });
  },
  convertTaskHandler(webixUI, id, node) {
    actionButtonsModule.handlers.convertTask(webixUI.config.taskId);
    innerHandlers.hideContextMenuPopup();
    userExtAnalytics.log('context_menu_click', { line: 'convert' });
    userExtAnalytics.log('task_grid_action', { action: 'type' });
  },
  showChangeTaskColorPopup(webixUI, id, node) {
    // set node for popup
    const positionX = window.innerWidth - (node.getBoundingClientRect().left + node.getBoundingClientRect().width);

    actionButtonsModule.handlers.changeTaskColor({
      node,
      taskId: webixUI.config.taskId,
      position: {
        pos: 'right',
        x: -6, // 170
        y: 3,
      },
    });
    userExtAnalytics.log('context_menu_click', { line: 'color' });
  },
  removeTaskHandler(webixUI, id, node) {
    const taskId = webixUI.config.taskId;

    actionButtonsModule.handlers.removeTaskById(taskId);
    userExtAnalytics.log('context_menu_click', { line: 'delete' });
    userExtAnalytics.log('task_grid_action', { action: 'delete' });
  },
  enableMassChange(webixUI, id, node) {
    const taskId = webixUI.config.taskId;
    const __idTask = parseInt(taskId, 10);

    massChangeModule.enable();
    massChangeModel.selectTask(__idTask, true);
    innerHandlers.hideContextMenuPopup();
  },
  removeProjectHandler(webixUI, id, node) {
    const task = gantt.getTask(webixUI.config.taskId);
    const viewData = ganttViewModel.getActiveViewData();
    const project = projectsModel.getProjectDataById(task.gantt_id);

    taskSettingsHandlers.deleteProjectConfirmation(project.name, result => {
      if (result) {
        if (viewData.ganttIDs.length === 1) {
          multiViewsProjects.removeMultiview(viewData.id);
          projectsModel.openProjectAfterDelete();
        } else {
          _.remove(viewData.ganttIDs, gantt_id => gantt_id === task.gantt_id);
          _.remove(viewData.config.order, gantt_id => gantt_id === task.gantt_id);
          multiViewsProjects.updateMultiview(viewData.id, viewData.config.order);

          app.trigger('activeMultiProjects:set', viewData.id, viewData.ganttIDs);
        }
      }
      app.trigger('gantt:keyboard:enable');
    });
    innerHandlers.hideContextMenuPopup();
  },
  copyTask(webixUI, id, node) {
    actionButtonsModule.handlers.fullCopyAction(webixUI.config.taskId);
    innerHandlers.enablePasteMenuItem(webixUI);
    innerHandlers.hideContextMenuPopup();
    userExtAnalytics.log('context_menu_click', { line: 'copy' });
  },
  pasteTask(webixUI, id, node) {
    const taskId = webixUI.config.taskId;

    if (actionButtonsModule.handlers.pasteValidation()) {
      const mode = (id === 'paste_task_above' ? 'insert_above' : 'insert_below');

      actionButtonsModule.handlers.insertTask(taskId, mode);
    }

    innerHandlers.hideContextMenuPopup();
    userExtAnalytics.log('context_menu_click', { line: 'paste' });
  },
  updateParentAfterDelete(taskData) {
    const parentData = globalStore.getters['tasksModel/getTaskByGanttId'](taskData.gantt_id, taskData.parent);
    const totalProject = globalStore.getters['tasksModel/getTotalEstimateDataForProject'](taskData.gantt_id);

    const parentChilds = globalStore.getters['tasksModel/getChildByTask'](taskData.gantt_id, +taskData.parent);

    if (+totalProject.id === +parentData.parent && parentChilds.length <= 1) {
      if (gantt.isTaskExists(parentData.id)) {
        gantt.deleteTask(parentData.id);
      } else {
        globalStore.dispatch('tasksModel/backgroundDeleteTask', parentData);
      }

      return;
    }

    if (_.isEmpty(parentChilds) || parentChilds.length === 1) {
      parentData.type = gantt.config.types.task;
      parentData.progress = 0;
      parentData.color = constants.DEFAULT_TYPE_COLORS.TASK;
      parentData.duration = taskData.duration;
      parentData.end_date = gantt.calculateEndDate(parentData.start_date, taskData.duration);

      if (gantt.isTaskExists(parentData.id)) {
        gantt.updateTask(parentData.id, _.assign(gantt.getTask(parentData.id), parentData));
      } else {
        globalStore.dispatch('tasksModel/backgroundUpdateTask', { taskData: parentData });
      }

      gantt.callEvent('customButtons:delete:from', [parentData]);
    }
  },
  removeTaskListView(webixUI, id, node) {
    const taskData = webixUI.config.taskData;

    app.trigger('gantt:keyboard:disable');

    innerHandlers.hideContextMenuPopup();

    taskSettingsHandlers.deleteTaskConfirmation(taskData, result => {
      if (result) {
        innerHandlers.updateParentAfterDelete(taskData);
        globalStore.dispatch('tasksModel/backgroundDeleteTask', taskData);

        userExtAnalytics.log('gantt_task_delete_confirmed');
      } else {
        userExtAnalytics.log('gantt_task_delete_cancelled');
      }

      app.trigger('gantt:keyboard:enable');
    });
  },
  showContextMenuListView(task, e, editProject) {
    const $$contextMenuList = $$(innerViews.contextMenuList.id);
    const $$contextMenuPopup = $$(innerViews.main.id);

    $$contextMenuList.config.taskId = task.id;
    $$contextMenuList.config.taskData = task;
    _.each($$contextMenuList.data.pull, item => {
      if (item.id === 'edit_task' || item.id === 'delete_task') {
        if (!editProject && item.id === 'delete_task') {
          $$contextMenuList.hideItem(item.id);
        } else {
          $$contextMenuList.showItem(item.id);
        }
      } else {
        $$contextMenuList.hideItem(item.id);
      }
    });

    if (e.target.closest('.column-options')) {
      $$contextMenuPopup.show(e.target.closest('.column-options').querySelector('.column-options-cog'));
    } else {
      $$contextMenuPopup.show(innerHelpers.getClickPosition(e));
    }
  },
  getWidthFromLocale: () => {
    let contextMenuWidth;

    if (user.locale !== 'es' && user.locale !== 'de') {
      contextMenuWidth = 236;
    } else if (user.locale === 'es') {
      contextMenuWidth = 262;
    } else if (user.locale === 'de') {
      contextMenuWidth = 344;
    }

    return contextMenuWidth;
  },
  goToProject: (webixUI, id) => {
    const gantt_id = gantt.getTask(webixUI.config.taskId).gantt_id;

    app.trigger('openProject', gantt_id);
    innerHandlers.hideContextMenuPopup();
  },

};

const contextMenuItemsConfig = [
  {
    id: 'edit_task',
    css: 'context_menu_list_border_bottom',
    icon: gantt._getSvgIcon('open', 'regular', 24),
    handler: innerHandlers.openEditTaskPopup,
    listViewHandler: innerHandlers.openEditTaskPopup,
  },
  {
    id: 'separatorBefore',
    $template: 'Separator',
  },
  {
    id: 'go_to_project',
    icon: gantt._getSvgIcon('link-2', 'regular', 24),
    handler: innerHandlers.goToProject,
  },
  {
    id: 'add_subtask',
    icon: gantt._getSvgIcon('add-task-1', 'color-regular', 24),
    handler: innerHandlers.addTaskCommonHandler,
  },
  {
    id: 'add_sibling_task',
    icon: gantt._getSvgIcon('paste-3', 'color-regular', 24),
    handler: innerHandlers.addTaskCommonHandler,
  },
  // {
  //   id: 'add_child_milestone',
  //   icon: gantt._getSvgIcon('add-milestone-1', 'color-regular', 24),
  //   handler: innerHandlers.addTaskCommonHandler,
  // },
  {
    id: 'add_sibling_milestone',
    icon: gantt._getSvgIcon('add-milestone-2', 'color-regular', 24),
    handler: innerHandlers.addTaskCommonHandler,
  },
  {
    id: 'move_indent',
    icon: gantt._getSvgIcon('add-task-3', 'color-regular', 24),
    handler: innerHandlers.addTaskCommonHandler,
  },
  {
    id: 'move_outdent',
    icon: gantt._getSvgIcon('add-task-4', 'color-regular', 24),
    handler: innerHandlers.addTaskCommonHandler,
  },
  {
    id: 'convert_in_milestone',
    icon: gantt._getSvgIcon('milestone', 'regular', 24),
    handler: innerHandlers.convertTaskHandler,
  },
  {
    id: 'separatorAfter',
    $template: 'Separator',
  },
  {
    id: 'convert_in_task',
    icon: gantt._getSvgIcon('task', 'regular', 24),
    handler: innerHandlers.convertTaskHandler,
  },
  {
    id: 'copy_task',
    css: 'context_menu_list_border_top',
    icon: gantt._getSvgIcon('copy', 'regular', 24),
    handler: innerHandlers.copyTask,
  },
  {
    id: 'paste_task',
    icon: gantt._getSvgIcon('paste', 'regular', 24),
    css: 'context_menu_list_right_arrow',
    submenu: [
      {
        id: 'paste_task_above',
        icon: gantt._getSvgIcon('paste-2', 'color-regular', 24),
        // css: "context_menu_list_border_bottom",
        handler: innerHandlers.pasteTask,
      },
      {
        id: 'paste_task_below',
        icon: gantt._getSvgIcon('paste-1', 'color-regular', 24),
        handler: innerHandlers.pasteTask,
      },
    ],
  },
  {
    id: 'paste_task_disabled',
    icon: gantt._getSvgIcon('paste', 'regular', 24),
    disabled: true,
    css: 'context_menu_list_disable',
  },
  {
    id: 'select_task_for_masschange',
    icon: gantt._getSvgIcon('checkbox-filled', 'regular', 24),
    handler: innerHandlers.enableMassChange,
  },
  {
    id: 'choose_task_color',
    css: 'context_menu_list_border_bottom context_menu_list_right_arrow',
    icon: gantt._getSvgIcon('color-backgroud', 'regular', 24),
    mouseMoveHandler: innerHandlers.showChangeTaskColorPopup,
  },
  {
    id: 'delete_task',
    css: 'context_menu_list_red',
    icon: gantt._getSvgIcon('delete', 'regular', 24),
    handler: innerHandlers.removeTaskHandler,
    listViewHandler: innerHandlers.removeTaskListView,
  },
  {
    id: 'choose_project_color',
    css: 'context_menu_list_border_bottom context_menu_list_right_arrow',
    icon: gantt._getSvgIcon('color-backgroud', 'regular', 24),
    mouseMoveHandler: innerHandlers.showChangeTaskColorPopup,
  },

];
const innerTemplates = {
  menuItem(item) {
    if (item.id !== 'separatorBefore' && item.id !== 'separatorAfter') {
      return `<div class='context_menu_list_item_icon'>${item.icon}</div>`
        + `<div class='context_menu_list_item_text'>${item.value}</div>`;
    }
  },
};

const innerViews = {
  main: {
    id: 'contextMenu',
  },
  contextMenuList: {
    id: 'contextMenuList',
    view: 'menu',
    css: 'context_menu_list',
    borderless: true,
    autoheight: true,
    subMenuPos: 'right',
    layout: 'y',
    data: [],
    type: {
      height: 32,
    },
    template: innerTemplates.menuItem,
    submenuConfig: {
      width: 200,
      id: 'context_menu_submenu',
      view: 'contextMenuSubmenu',
      borderless: true,
      padding: 0,
      type: {
        height: 32,
      },
      css: 'context_menu_submenu',
      template: innerTemplates.menuItem,
      on: {
        onBeforeShow() {
          app.trigger('context_menu:mouse:move');
        },
        onItemClick(id, e, node) {
          const item = this.getItem(id);
          const $$contextMenuList = $$(innerViews.contextMenuList.id);

          if (routerHelper.isListViewRoute()) {
            item.listViewHandler && item.listViewHandler($$contextMenuList, id, node);
          } else {
            item.handler && item.handler($$contextMenuList, id, node);
          }
        },
      },
    },
    on: {
      onItemClick(id, e, node) {
        const item = this.getItem(id);

        if (routerHelper.isListViewRoute()) {
          item.listViewHandler && item.listViewHandler(this, id, node);
        } else {
          item.handler && item.handler(this, id, node);
        }

        if (id === 'choose_task_color') {
          item.mouseMoveHandler && item.mouseMoveHandler(this, id, node);
        }
      },
      onMouseMove(id, e, node) {
        const item = this.getItem(id);

        if (!_.includes(['choose_task_color', 'paste_task'], id)) {
          app.trigger('context_menu:mouse:move');
        }

        item.mouseMoveHandler && item.mouseMoveHandler(this, id, node);
      },
    },
  },
};

const innerHelpers = {
  getClickElement(event) {
    return event.target || event.srcElement;
  },
  getClickPosition(event) {
    return {
      x: event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft,
      y: event.clientY + document.body.scrollTop + document.documentElement.scrollTop,
    };
  },
  getMenuItems(configs) {
    return _.map(configs, config => innerHelpers.createMenuItem(config));
  },
  createMenuItem(config) {
    const itemValue = (config.id !== 'separatorBefore' && config.id !== 'separatorAfter') ? __(`gantt_${config.id}`) : '';
    const menuItem = {
      id: config.id,
      icon: config.icon,
      disabled: !!config.disabled,
      $css: config.css,
      handler: config.handler,
      listViewHandler: config.listViewHandler,
      mouseMoveHandler: config.mouseMoveHandler,
      value: itemValue,
      $template: config.$template,
    };

    if (config.submenu && config.submenu.length > 0) {
      menuItem.submenu = innerHelpers.getMenuItems(config.submenu);
    }

    return menuItem;
  },
  isTaskRow(trg) {
    return _.includes(trg.className, 'gantt_task_row');
  },
  isButtonRow(id) {
    const taskData = gantt.getTask(id);

    return taskData && taskData.type === gantt.config.types.button;
  },
  isTotalEstimate(id) {
    let taskData;

    if (gantt.isTaskExists(id)) {
      taskData = gantt.getTask(id);
    } else {
      taskData = globalStore.getters['tasksModel/getTask'](id);
    }

    return taskData && taskData.$level === 0;
  },
  getPosition(state) {
    const leftSideBarWidth = Store.getters.leftSidebarWidth;

    state.width = document.body.clientWidth - leftSideBarWidth;
    state.left = leftSideBarWidth;
    state.top = 0;
  },
};

const innerSettings = {
  ganttId: null,
  contextMenuWidth: 236,

};

const outputObject = {
  init: {
    beforeInit: _.once(innerHandlers.beforeRun),
    run: _.once(innerHandlers.run),
  },
  handlers: {
    showContextMenuListView: innerHandlers.showContextMenuListView,
    updateParentAfterDelete: innerHandlers.updateParentAfterDelete,
  },
};

app.on('taskSettings:init', (taskData, ganttId, initOptions) => {
  window.taskSettingsData = {
    taskData, ganttId, initOptions,
  };

  const taskID = taskData.id || taskData.task_id;

  if (gantt.config.masschange) {
    return false;
  }

  if (!innerHelpers.isTotalEstimate(taskID)) {
    innerHandlers.editTaskRoute(taskID);
  }
});

app.on('gantt:close:contextMenu', () => {
  innerHandlers.hideContextMenuPopup();
});

export default outputObject;
