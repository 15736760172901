import jiraModel from '../../../../models/jira';

const __ = window.__;

export default {
  getTemplate() {
    return `${"<div class='jira-view'>"
      + "<div class='jira-view-desc'>"
      + "<div class='jira-view-desc-item'>"}${__('jira_integration_third_step_1')}</div>`
      + '</div>'
      + '<div class=\'jira-view-screen third-1\'>' + '' + '</div>'
      + '<div class=\'jira-view-desc\'>'
      + `<div class='jira-view-desc-item multiline only-text'>${__('jira_integration_third_step_2')}</div>`
      + '</div>'
      + '<div class=\'jira-view-screen third-2\'>'
      + `<div class='jira-view-screen-block-head'>${__('jira_integration_third_step_3')}</div>`
      + '<div class=\'jira-view-screen-block-body\'>'
      + '<div class=\'jira-view-screen-block-body-item\'>'
      + `<div class='jira-view-screen-block-body-item-label'>${__('jira_integration_third_step_consumer_key')}:</div>`
      + `<input readonly onClick='this.select();' class='jira-view-screen-block-body-item-input consumer_key' value='${jiraModel.commonFields.consumer_key}' />`
      + '</div>'
      + '<div class=\'jira-view-screen-block-body-item\'>'
      + `<div class='jira-view-screen-block-body-item-label'>${__('jira_integration_third_step_consumer_name')}:</div>`
      + `<input readonly onClick='this.select();' class='jira-view-screen-block-body-item-input consumer_name' value='${jiraModel.commonFields.consumer_name}' />`
      + '</div>'
      + '<div class=\'jira-view-screen-block-body-item\'>'
      + `<div class='jira-view-screen-block-body-item-label'>${__('jira_integration_third_step_public_key')}:</div>`
      + `<textarea readonly onClick='this.select();' class='jira-view-screen-block-body-item-textarea public_key'>${jiraModel.commonFields.public_key}</textarea>`
      + '</div>'
      + '<div class=\'jira-view-screen-block-body-item\'>'
      + `<div class='jira-view-screen-block-body-item-label'> ${__('jira_integration_third_step_callback_url')}:</div>`
      + `<input readonly onClick='this.select();' class='jira-view-screen-block-body-item-input cunsomer_callback' value='${jiraModel.commonFields.cunsomer_callback}' />`
      + '</div>'
      + '</div>'
      + '</div>'
      + '<div class=\'jira-view-desc\'>'
      + `<div class='jira-view-desc-item multiline only-text' id='confirm-jira'><input type='checkbox'  class='accept' id="accept-confirm" /> <label for="accept-confirm">${__('jira_integration_confirm_jira_data_backup')}</label></div>`
      + '</div>'
      + '</div>';
  },
};
